import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_dashboard = _resolveComponent("admin-dashboard")!
  const _component_superadmin_dashboard = _resolveComponent("superadmin-dashboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authStore.state.user.role === _ctx.UserRole.Admin)
      ? (_openBlock(), _createBlock(_component_admin_dashboard, { key: 0 }))
      : (_ctx.authStore.state.user.role === _ctx.UserRole.SuperAdmin)
        ? (_openBlock(), _createBlock(_component_superadmin_dashboard, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}