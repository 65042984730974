import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1ea3152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "box-icon" }
const _hoisted_3 = { class: "box-title" }
const _hoisted_4 = { class: "box-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}