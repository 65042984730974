
import AdminDashboard from "@/components/Dashboard/AdminDashboard.vue";
import SuperadminDashboard from "@/components/Dashboard/SuperadminDashboard.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  components: { AdminDashboard, SuperadminDashboard },
  mixins: [helperMixin]
});
