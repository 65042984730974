
// import { Chart, registerables } from "chart.js";
import { defineComponent, ref } from "vue";
import { helperMixin } from "@/mixins";
import DashboardBox from "@/components/Dashboard/DashboardBox.vue";
import crew from "@/http/crew";
import fleet from "@/http/fleet";
import JobActiveTable from "@/components/Job/JobActiveTable.vue";

export default defineComponent({
  components: { DashboardBox, JobActiveTable },
  mixins: [helperMixin],

  setup() {
    return {
      incomingApplicantChart: (ref(null) as unknown) as HTMLCanvasElement
    };
  },

  data() {
    return {
      crewCount: 0,
      fleetCount: 0
    };
  },

  async mounted() {
    // Chart.register(...registerables);

    this.crewCount = await crew().count();

    this.fleetCount = await fleet().count();
  },

  methods: {
    // initIncomingApplicantChart() {
    //   if (!this.incomingApplicantChart) return;
    //   const labels = ["January", "February", "March", "April", "May", "June"];
    //   const data = {
    //     labels: labels,
    //     datasets: [
    //       {
    //         label: "",
    //         backgroundColor: "#0095da",
    //         borderColor: "#4ac6ff",
    //         data: [0, 10, 5, 2, 20, 30, 45]
    //       }
    //     ]
    //   };
    //   new Chart(this.incomingApplicantChart, {
    //     type: "line",
    //     data: data,
    //     options: {}
    //   });
    // }
  }
});
