
import {
  infinityScrollTable,
  helperMixin,
} from "@/mixins";
import { JobFilter, JobPaginate } from "@/models/job";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";

export default defineComponent({
  components: {
    ActionTableColumn,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
  },
  mixins: [
    helperMixin,
    infinityScrollTable<JobPaginate, JobFilter>(jobHttp(), {
      position_in: [],
      is_active: 1
    })
  ],
});
