
import { helperMixin } from "@/mixins";
import { defineComponent, ref, PropType } from "vue";
import { Chart, registerables } from "chart.js";
import { FilterChartPeriode } from "@/enums";
import { FilterChart } from "@/lib/resourceHttp";
import moment from "moment";

export default defineComponent({
  mixins: [helperMixin],

  props: {
    resourceHttp: {
      type: Object as PropType<
        import("@/lib/resourceHttp").default<any, any, any>
      >,
      required: true
    },

    title: {
      type: String
    },

    canvasHeight: {
      type: String,
      default: "0"
    },

    canvasWidth: {
      type: String,
      default: "0"
    }
  },

  setup() {
    return {
      root: (ref(null) as unknown) as HTMLElement
    };
  },

  data: () => {
    const now = moment();

    return {
      filterTimeout: 0,
      FilterChartPeriode,
      filter: {
        periode: FilterChartPeriode.day,
        month: parseInt(now.format("MM")),
        year: parseInt(now.format("YYYY"))
      } as FilterChart
    };
  },

  async mounted() {
    Chart.register(...registerables);

    await this.$nextTick();

    this.build();
  },

  methods: {
    async build() {
      this.root.querySelector("canvas")?.remove();

      let canvas: HTMLCanvasElement = document.createElement("canvas");

      if (this.canvasHeight) {
        canvas.setAttribute("height", this.canvasHeight);
      }

      if (this.canvasWidth) {
        canvas.setAttribute("width", this.canvasWidth);
      }

      this.root.appendChild(canvas);

      const data = await this.resourceHttp.chart(this.filter);

      const labels: string[] = [];

      const counts: number[] = [];

      for (let key in data) {
        labels.push(key);
        counts.push(data[key]);
      }

      new Chart(canvas, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              label: this.title,
              backgroundColor: "#0095da",
              borderColor: "#4ac6ff",
              data: counts
            }
          ]
        },
        options: {}
      });
    }
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        clearTimeout(this.filterTimeout);

        this.filterTimeout = setTimeout(() => {
          this.build();
        }, 200);
      }
    }
  }
});
